import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import LmnpPage from "~/components/Simulators/LmnpPage";

export default function LmnpRoute() {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Un terrain, une maison ou un immeuble à vendre ? Recevez une estimation d'achat de votre bien sous 72h, en direct promoteur et au meilleur prix.",
    },
  };

  return (
    <>
      <Title>
        Simulation LMNP : votre simulateur en Loueur Meublé non professionnel
      </Title>
      <MetaTags tags={[metaDesc]} />
      <LmnpPage />
    </>
  );
}
